import React, { useState } from "react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { Typography15, Button, Dim, Icon, MenuButton, MenuSizeType } from "@slid/slid-ips";
import { SlidTextLogo } from "../icons/SlidTextLogo";
import { SlidTextSmallLogo } from "../icons/SlidTextSmallLogo";
import { MenuIcon } from "../icons/MenuIcon";
import LanguageMenu from "../menus/LanguageMenu";
import SocialMediaIconContainer from "../containers/SocialMediaIconContainer";

import env from "@/config/env";
import { trackEvent } from "src/utils/eventTracking";
import { deviceType } from "react-device-detect";
import SignUpCTAButton from "../buttons/SignUpCTAButton";

interface LandingNavProps {
  executeScrollToFeature: () => void;
  executeScrollToDownload: () => void;
  isMobile: boolean;
  isSmallMobile: boolean;
  isDesktop: boolean;
}

const LandingNav = ({ executeScrollToFeature, executeScrollToDownload, isMobile, isSmallMobile, isDesktop }: LandingNavProps) => {
  const router = useRouter();
  const { t } = useTranslation(["Landing", "Navigation"]);

  const [isMobileMenuClicked, setIsMobileMenuClicked] = useState<boolean>(false);

  const LINK_LIST = [
    {
      group: t("Product", { ns: "Landing" }),
      list: [
        {
          label: t("Feature", { ns: "Landing" }),
          callback: () => {
            setIsMobileMenuClicked(false);
            executeScrollToFeature();
            trackEvent({
              eventType: "Click FEATURE button",
              eventProperties: {
                origin: "nav",
                deviceType: deviceType,
              },
            });
          },
        },
        {
          label: t("Pricing"),
          callback: () => {
            trackEvent({
              eventType: "Click PRICING button",
              eventProperties: {
                origin: "nav",
                deviceType: deviceType,
              },
            });
            window.open(`${SLID_WEB_APP_URL}/pricing`);
          },
        },
      ],
    },
    {
      group: t("Download", { ns: "Landing" }),
      list: [
        {
          label: t("WebExtension", { ns: "Landing" }),
          callback: () => {
            setIsMobileMenuClicked(false);
            executeScrollToDownload();
            trackEvent({
              eventType: "Click SCROLL TO DOWNLOAD button",
              eventProperties: {
                origin: "nav",
                deviceType: deviceType,
              },
            });
          },
        },
        {
          label: t("DesktopApp", { ns: "Landing" }),
          callback: () => {
            setIsMobileMenuClicked(false);
            executeScrollToDownload();
            trackEvent({
              eventType: "Click SCROLL TO DOWNLOAD button",
              eventProperties: {
                origin: "nav",
                deviceType: deviceType,
              },
            });
          },
        },
        {
          label: t("MobileApp", { ns: "Landing" }),
          callback: () => {
            setIsMobileMenuClicked(false);
            executeScrollToDownload();
            trackEvent({
              eventType: "Click SCROLL TO DOWNLOAD button",
              eventProperties: {
                origin: "nav",
                deviceType: deviceType,
              },
            });
          },
        },
      ],
    },
    {
      group: t("Support", { ns: "Landing" }),
      list: [
        {
          label: t("Updates", { ns: "Landing" }),
          callback: () => {
            trackEvent({
              eventType: "Click link in nav",
              eventProperties: {
                origin: "nav",
                deviceType: deviceType,
                label: t("Updates", { ns: "Landing" }),
                link: t("UpdatesLink", { ns: "Landing" }),
              },
            });
            window.open(t("UpdatesLink", { ns: "Landing" }));
          },
        },
        {
          label: t("FAQs", { ns: "Landing" }),
          callback: () => {
            trackEvent({
              eventType: "Click link in nav",
              eventProperties: {
                origin: "nav",
                deviceType: deviceType,
                label: t("FAQs", { ns: "Landing" }),
                link: t("FAQsLink", { ns: "Landing" }),
              },
            });
            window.open(t("FAQsLink", { ns: "Landing" }));
          },
        },
        {
          label: t("Contact", { ns: "Landing" }),
          callback: () => {
            trackEvent({
              eventType: "Click link in nav",
              eventProperties: {
                origin: "nav",
                deviceType: deviceType,
                label: t("Contact", { ns: "Landing" }),
                link: t("ContactLink", { ns: "Landing" }),
              },
            });
            window.open(t("ContactLink", { ns: "Landing" }));
          },
        },
      ],
    },
    {
      group: t("Company", { ns: "Landing" }),
      list: [
        {
          label: t("About", { ns: "Landing" }),
          callback: () => {
            trackEvent({
              eventType: "Click link in nav",
              eventProperties: {
                origin: "nav",
                deviceType: deviceType,
                label: t("About", { ns: "Landing" }),
                link: t("AboutLink", { ns: "Landing" }),
              },
            });
            window.open(t("AboutLink", { ns: "Landing" }));
          },
        },
        {
          label: t("PrivacyPolicy", { ns: "Landing" }),
          callback: () => {
            trackEvent({
              eventType: "Click link in nav",
              eventProperties: {
                origin: "nav",
                deviceType: deviceType,
                label: t("PrivacyPolicy", { ns: "Landing" }),
                link: t("PrivacyPolicyLink", { ns: "Landing" }),
              },
            });
            window.open(t("PrivacyPolicyLink", { ns: "Landing" }));
          },
        },
        {
          label: t("TermsOfService", { ns: "Landing" }),
          callback: () => {
            trackEvent({
              eventType: "Click link in nav",
              eventProperties: {
                origin: "nav",
                deviceType: deviceType,
                label: t("TermsOfService", { ns: "Landing" }),
                link: t("TermsOfServiceLink", { ns: "Landing" }),
              },
            });
            window.open(t("TermsOfServiceLink", { ns: "Landing" }));
          },
        },
      ],
    },
  ];

  const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;

  return (
    <>
      {isDesktop ? (
        <HeaderContainer isSmallMobile={isSmallMobile} isMobile={isMobile}>
          <HeaderLeftContainer>
            <LogoWrapper onClick={() => router.push("/")} isDesktop={!isMobile && !isSmallMobile}>
              <SlidTextLogo width={72} height={24} />
            </LogoWrapper>
            <MenuContainer>
              <MenuButton
                color={"--gray9"}
                onClick={() => {
                  executeScrollToFeature();
                  trackEvent({
                    eventType: "Click FEATURE button",
                    eventProperties: {
                      origin: "nav",
                      deviceType: deviceType,
                    },
                  });
                }}
                menuValue={t("Feature", { ns: "Navigation" })}
                size={MenuSizeType.Large}
                isDropDownMenu={false}
              />
              <MenuButton
                color={"--gray9"}
                onClick={() => {
                  executeScrollToDownload();
                  trackEvent({
                    eventType: "Click SCROLL TO DOWNLOAD button",
                    eventProperties: {
                      origin: "nav",
                      deviceType: deviceType,
                    },
                  });
                }}
                menuValue={t("Download", { ns: "Navigation" })}
                size={MenuSizeType.Large}
              />
              <MenuButton
                color={"--gray9"}
                onClick={() => {
                  trackEvent({
                    eventType: "Click PRICING button",
                    eventProperties: {
                      origin: "nav",
                      deviceType: deviceType,
                    },
                  });
                  window.open(`${SLID_WEB_APP_URL}/pricing`);
                }}
                menuValue={t("Pricing", { ns: "Navigation" })}
                size={MenuSizeType.Large}
              />
            </MenuContainer>
          </HeaderLeftContainer>
          <HeaderRightWrapper>
            <MenuButton
              color={"--gray9"}
              onClick={() => {
                trackEvent({
                  eventType: "Click SIGN IN button",
                  eventProperties: {
                    origin: "nav",
                    deviceType: deviceType,
                  },
                });
                router.push(`${SLID_WEB_APP_URL}/sign-in`);
              }}
              menuValue={t("signIn", { ns: "Navigation" })}
              size={MenuSizeType.Large}
            />
            <SignUpCTAButton origin="nav" size={`medium`} />
          </HeaderRightWrapper>
        </HeaderContainer>
      ) : (
        <HeaderContainer isSmallMobile={isSmallMobile} isMobile={isMobile}>
          <LogoWrapper
            onClick={() => {
              router.push("/");
            }}
            isDesktop={!isMobile && !isSmallMobile}
          >
            <SlidTextSmallLogo width={60} height={21} />
          </LogoWrapper>
          <IconWrapper
            onClick={() => {
              setIsMobileMenuClicked(true);
            }}
          >
            <MenuIcon width={24} height={24} />
          </IconWrapper>
        </HeaderContainer>
      )}
      {isMobileMenuClicked && (
        <>
          <MobileMenuModalWrapper>
            <MobileMenuModalContainer isSmallMobile={isSmallMobile} isMobile={isMobile}>
              <ModalHeader isSmallMobile={isSmallMobile} isMobile={isMobile}>
                <LogoWrapper onClick={() => router.push("/")} isDesktop={!isMobile && !isSmallMobile}>
                  <SlidTextSmallLogo width={60} height={21} />
                </LogoWrapper>
                <IconWrapper onClick={() => setIsMobileMenuClicked(false)}>
                  <Icon icon={`x24`} color={`--gray15`} />
                </IconWrapper>
              </ModalHeader>
              <ModalBodyContainer>
                {LINK_LIST.map((item, index) => {
                  return (
                    <LinkGroupWrapper key={index}>
                      <Typography15 text={item.group} color={`--gray13`} weight={700} />
                      <LinkItemWarpper>
                        {item.list.map((item, index) => {
                          return (
                            <LinkTextWrapper key={index} onClick={item.callback}>
                              <Typography15 text={item.label} color={`--gray9`} marginTop={`12px`} />
                            </LinkTextWrapper>
                          );
                        })}
                      </LinkItemWarpper>
                    </LinkGroupWrapper>
                  );
                })}
              </ModalBodyContainer>
              <ModalFooterContainer>
                <FooterButtons>
                  <MenuWrapper>
                    <LanguageMenu color={`--gray9`} />
                  </MenuWrapper>
                  <SocialMediaIconContainer />
                </FooterButtons>
                <FooterSignInButtons>
                  <Button
                    text={t("signIn", { ns: "Navigation" })}
                    size={`maxWidthLarge`}
                    appearance={`secondary`}
                    callback={() => {
                      trackEvent({
                        eventType: "Click SIGN IN button",
                        eventProperties: {
                          origin: "nav",
                          deviceType: deviceType,
                        },
                      });
                      router.push(`${SLID_WEB_APP_URL}/sign-in`);
                    }}
                  />
                  <SignUpCTAButton origin="nav" size={`maxWidthLarge`} />
                </FooterSignInButtons>
              </ModalFooterContainer>
            </MobileMenuModalContainer>
          </MobileMenuModalWrapper>
          <Dim
            callBack={() => {
              setIsMobileMenuClicked(false);
            }}
            type={"gray9"}
          />
        </>
      )}
    </>
  );
};

const HeaderContainer = styled.nav<{ isMobile: boolean; isSmallMobile: boolean }>`
  width: 90%;
  max-width: ${({ isSmallMobile, isMobile }) => (isSmallMobile ? "320px" : isMobile ? "580px" : "775px")};
  min-width: 320px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: ${({ isSmallMobile, isMobile }) => (isSmallMobile ? "40px" : isMobile ? "40px" : "32px")};
  left: 50%;
  transform: translateX(-50%);
  padding: ${({ isSmallMobile, isMobile }) => (isSmallMobile ? "14px" : isMobile ? "14px" : "8px")};

  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.2);

  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(28px);
  -moz-backdrop-filter: blur(28px);
  -o-backdrop-filter: blur(28px);
  backdrop-filter: blur(28px);

  border-radius: 8px;
  z-index: 999;
`;

const HeaderLeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.div<{ isDesktop: boolean }>`
  padding: ${({ isDesktop }) => (isDesktop ? "10px 0 10px 23px" : "0")};
  cursor: pointer;
`;

const MenuContainer = styled.div`
  display: flex;
  margin-left: 40px;
  gap: 16px;
`;

const HeaderRightWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const MobileMenuModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 700px;
  min-width: 320px;
  background-color: #fff;
  z-index: 99999999;
  overflow-y: scroll;
`;

const MobileMenuModalContainer = styled.div<{ isMobile: boolean; isSmallMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 650px;
  max-width: ${({ isMobile, isSmallMobile }) => (isMobile ? "580px" : isSmallMobile ? "320px" : "none")};
`;

const ModalHeader = styled.div<{ isMobile: boolean; isSmallMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${({ isMobile, isSmallMobile }) => (isMobile ? "580px" : isSmallMobile ? "320px" : "none")};
  padding: 20px;
`;

const ModalBodyContainer = styled.div`
  margin-top: 12px;
  padding: 20px;
  display: grid;
  gap: 48px 0;
  grid-template-columns: repeat(2, minmax(calc(50% - 20px), auto));
  grid-template-rows: repeat(2, minmax(100px, auto));
  width: 100%;
  min-width: 100%;
  height: 100%;
`;

const LinkGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkItemWarpper = styled.div`
  margin-top: 8px;
`;

const LinkTextWrapper = styled.a`
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

const ModalFooterContainer = styled.div`
  width: 100%;
  padding: 20px;
`;

const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MenuWrapper = styled.div`
  z-index: 9999;
`;

const FooterSignInButtons = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 12px;
`;

export default LandingNav;
